export const FirebaseConfig = {
	"projectId": "abc-minicab-vr",
	"appId": "1:898576425421:web:a6d8d2d76f8630923ba484",
	"databaseURL": "https://abc-minicab-vr-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "abc-minicab-vr.appspot.com",
	"locationId": "asia-south1",
	"apiKey": "AIzaSyAVYjjtj8B_lXRoBZOsViDd6aPyLutfeGo",
	"authDomain": "abc-minicab-vr.firebaseapp.com",
	"messagingSenderId": "898576425421",
	"measurementId": "G-BRQEQCWFS9"
};